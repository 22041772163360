
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


.main_sec{ color: #fff; padding:45px 0 45px; font-size: 14px; font-family: "Roboto", sans-serif;}
.page_title h1{ font-size: 22px; margin-bottom: 20px; font-weight: normal; }


.form_sec .form-group{ margin-bottom: 12px;}
.form_sec .form-control{ font-size: 13px; height: 35px; border-radius: 20px; color: #000; border:none; background-color: #fff; padding-right: 25px;}
.form_sec select.form-control{ /* color:#c7c7c7; */ background-image: none;}
.form_sec select.form-control:focus{ color: #000;}
.form_sec .form-control::-webkit-input-placeholder {  color: #c7c7c7; }
.form_sec .form-control::-moz-placeholder { color: #c7c7c7;  }
.form_sec .form-control:-ms-input-placeholder { color: #c7c7c7;  }
.form_sec .form-control:-moz-placeholder { color: #c7c7c7;  }
.form_sec textarea.form-control{ height: 80px; resize: none;}
.form_sec .form-control:focus{ box-shadow: none;}
.form_sec{ max-width: 500px; margin: auto;}
.form_sec .form-control.btn_cls{ font-size: 16px; color: #427142; border: 1px solid transparent; transition: 0.4s;}
.form_sec .btn_sec { margin-top: 35px;}
.form_sec .form-control.btn_cls:hover{ border-color: #fff; color: #fff; background-color: transparent; }
.form_sec .form-group{ position: relative;}
.form-group.success:after , .form-group.error:after{ content: ""; position: absolute; z-index: 1; right: 8px; top:8px; width: 20px; height: 20px; background-image: url(../images/circle-check-solid.svg); }
.form-group.error:after{ background-image: url(../images/circle-xmark-solid.svg); }
.form_sec .form-group .icon_box{ position: absolute; top: 5px; right: 8px; width: 20px; height: 20px;}

.btn_cls{ border:2px solid #fff; background-color: transparent; color: #fff; border-radius: 20px; padding: 4px 20px; margin-bottom: 12px; transition: 0.4s;}
.btn_cls:hover{ background-color: #fff; color:#427142;}
.link_text{ text-align: center; display: block;}
.link_text a{ color: #fff; font-size: 14px; text-decoration: none;}

.main_sec{ max-width: 500px; margin: auto;}
.main_page_title {
    background: #fff;
    padding-left: 15px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    margin-bottom: 20px;
    border: 2px solid #fff;
}
.main_page_title h1{ margin-bottom: 0; font-size: 18px; color: #427142; text-transform: uppercase;}
.default_btn {  display: inline-block;  background: #427142;  color: #fff;  text-decoration: none;  border-radius: 30px;  padding: 6px 20px;
text-transform: uppercase;  font-size: 13px; transition: 0.4s; border: 1px solid #427142;}
.default_btn:hover{ background-color: #fff; color: #427142;}
.default_btn.bg_white{ background-color: #fff; color: #000; border-color: #fff;}
.default_btn.bg_white:hover{ background-color: transparent; color: #fff;}

.dash_item a{ background-color: #fff; padding:15px; color: #427142; display: inline-block; text-decoration: none; border-radius: 20px; width: 100%;
     min-height: 90px; display: flex; align-items: center; justify-content: center; text-transform: uppercase; text-align: center; font-weight: 500; font-size: 14px; transition: 0.4s;  }
.dashboard_list .row{ margin: -6px;}
.dashboard_list .row [class*="col"]{ padding:6px;}

.user_login { display: flex;  align-items: center;  flex-wrap: wrap;}
.user_id{ font-size: 11px; margin-right: 5px; text-align: center; line-height: 15px; text-transform: uppercase;}
.user_profile{ width: 35px; height:35px; border-radius: 30px;}

.dashboard_list .page_title h2{ font-size: 20px; margin-bottom: 20px; }
.project_sec .dash_item a{ min-height: inherit; padding: 10px; text-transform: capitalize; }

.content_box {
    background: #fff;
    text-align: center;
    color: #427142;
    border-radius: 20px;
    padding: 15px;
}
.content_box p{ font-size: 12px; line-height: 14px; font-weight: 500; }


.radio_sec input[type="radio"] {
    display: none;
  }

.radio_sec label {
    cursor: pointer;
    position: relative;
    font-size: 14px;
    padding-right: 30px;
  }

  .radio_sec label::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: border-color 400ms ease;
  }

  .radio_sec label::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    top: 50%;
   right: 0;
    transform: translateY(-50%) scale(0);
    transition: transform 400ms ease;
  }

  .radio_sec input[type="radio"]:checked + label::before {
    border-color: #fff;
  }

  .radio_sec input[type="radio"]:checked + label::after {
    transform: translateY(-50%) scale(0.55);
  }

.sm-input-file{ opacity: 0; position: absolute; pointer-events: none;}
.file-input-container.form-control {
    padding: 3px 3px 3px 15px;
 display: flex; align-items: center; justify-content: space-between; color: #c7c7c7;
}
label.for-sm-input-file.default_btn{ padding: 4px 20px;}


.avatar-upload {
    position: relative;
    text-align: center;
  }

  .avatar-upload .avatar-edit {
    position: absolute;
    right: 0px;
    z-index: 1;
    top: 0px;
  }
  .avatar-upload .avatar-edit input {
    display: none;
  }
  .avatar-upload .avatar-edit input + label {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    cursor: pointer;
  }

  .avatar-upload .avatar-preview {
    width: 100%;
    height: 135px;
    position: relative;
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
  }
  .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }


section.btm_sec{ position: fixed; bottom: 0; left: 0; background-color: #fff; width: 100%; border-top: 1px solid #fff;}
section.btm_sec .menu_container{ max-width: 500px; margin: auto; padding:5px 15px; text-align: right; position: relative; z-index: 9;}

.header_navigation ul{ padding:20px 0 0;}
.header_navigation.visible {right: 0; -webkit-transition: right .4s ease;-moz-transition: right .4s ease;-ms-transition: right .4s ease;
-o-transition: right .4s ease;transition: right .4s ease;}
.header_navigation {z-index: 1; width: 350px; display: block;bottom: 40px;position: fixed; right: -100%; top: 0px;
    -webkit-transition: all .4s ease;-moz-transition: all .4s ease;-ms-transition: all .4s ease;
    -o-transition: all .4s ease;transition: all .4s ease;margin: 0;border: 0;border-radius: 0;
    overflow-y: auto;overflow-x: hidden; height: calc( 100vh - 40px ); background: #fff; box-shadow: -3px 0px 31px 0px rgba(0,0,0,0.13); }
.header_navigation ul li a {font-size: 14px;padding: 12px 20px;display: block;
color: #427142; text-align: left; text-decoration: none;}
.header_navigation ul li{ width:100%; display:block; }
.header_navigation ul li a:hover { background-color: #427142;  color: #fff !important;}

  .navbar-fostrap {display: inline-block;cursor: pointer;margin: 0px 0px -5px 10px;}
  .navbar-fostrap span { cursor: pointer;  z-index: 1; height: 4px; background: #427142; margin: 4px 0; display: block;transition: 0.3s ease; width: 30px;}
  .show_menu .navbar-fostrap span:nth-child(2){
    opacity: 0;
  }

  .show_menu .navbar-fostrap span:nth-child(1){
    -webkit-transform: translateY(7px) rotate(45deg);
    -ms-transform: translateY(7px) rotate(45deg);
    -o-transform: translateY(7px) rotate(45deg);
    transform: translateY(7px) rotate(45deg);
  }

  .show_menu .navbar-fostrap span:nth-child(3){
    -webkit-transform: translateY(-9px) rotate(-45deg);
    -ms-transform: translateY(-9px) rotate(-45deg);
    -o-transform: translateY(-9px) rotate(-45deg);
    transform: translateY(-9px) rotate(-45deg);
  }

  @media(max-width:767px){
  .header_navigation{ width: 100%;}
  .form_sec{ padding: 0 20px;}

    .avatar-upload .avatar-preview {
      height: 90px;
    }
  }