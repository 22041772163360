.error{color: #ff7c7c;}
.asterisk{color: #ff7c7c;}

.input-password {
    position: relative;
}
.form-section .form-group .input-password input.form-control {
    padding-right: 45px;
}
.input-password .visibility-icon {
    align-items: center;
    color: #427142 !important;
    color: var(--dark-color);
    display: flex;
    height: 35px;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 35px;
    text-decoration: none;
}
.theme-bg{
    background-color: #427142;
}
.cardImg{
    background-color: white;
    width: 120px;
    height: 100px;
    text-align: center;
    border-radius: 11%;
}
.linkTag {
    color: #fff;
    text-decoration: none;
}
.linkTag:hover {
    color: #fff;
}
.greenLinkTag:hover, .theme-color {
    color: #427142 !important;
}
.cursor-pointer{
    cursor: pointer !important;
}

.btn.btn-primary {
    align-items: center;
    background-color: #427142;
    border: 0;
    border-radius: 100px;
    color: #fff;
    display: flex;
    font-weight: 500;
    height: 50px;
    justify-content: space-between;
    padding: 0 22px;
}

.min-height-72vh {
	min-height: 72vh !important;
}
.mt--3{margin-top: -1rem !important;}
.resize-none{resize: none !important;}
.bg-white{background-color: white;}
.color-black, .color-black:hover, .color-black:active{color: black;}
.max-zIndex{z-index: 999;}
.float-left{float: left;}
.max-w-100 {max-width: 100% !important;}
.max-h-100 {max-height: 100% !important;}
.pro-list span {
    position: relative;
    width: 100%;
}
.pro-list span i {
    position: absolute;
    right: 0;
    top: 36%;
    float: right;
    font-size: 20px;
}
.icon, .icon a {
	align-items: center;
}
.icon a {
	border: 1px solid #ddd;
	border-radius: 12px;
	height: 38px;
	justify-content: center;
	margin: 0 0 0 13px;
	transition: all .4s;
	-webkit-transition: all .4s;
	-moz-transition: all .4s;
	-ms-transition: all .4s;
	-o-transition: all .4s;
	width: 38px;
    color: black;
    text-decoration: none;
    display: flex;
}
.icon a:hover {
	background-color: #427142;
	color: #fff
}
.selected-link{
    color: #fff !important;
    background-color:black !important;
}
.selected-link:hover, .selected-link:active{
    color: #fff !important;
}
.background-repeat {
    background-repeat: repeat !important;
}
.text-decoration-none{
    text-decoration: none !important;
}

.badge-ul li{
    list-style: none;
    margin-top: 15px;
    float: left;
    /* position: relative; */
}
.badge-ul li span, .badge-span {
    color: #fff !important;
    border-radius: 50rem !important;
    background-color: black !important;
    padding: .5rem;
    margin-right: 2px;
    font-size: 12px;
}
.badge-span{background-color: #427142 !important;}

.float-inline-end {
    float: inline-end;
}
.logo-edit{
	background-color: black;
	border-radius: 37%;
	color: #fff;
	padding: 8px;
}
.p-t-14{
    padding-top: 14px !important;
}

.p-6-11{ padding: 6px 11px !important;}
.float-right{float: right !important;}

.position-inherit{
    position: inherit !important;
}

.w-80{
    width: 80% !important;
}
.child-a-black a{color: black;}
.child-a-black a:hover, .child-a-black a:active{color: black;}